import debounce from './debounce';

class AdProgramSearch extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.abortController = new AbortController();
        this.domParser = new DOMParser();

        this.elements = {
            wrapper: this.querySelector('form.program-search-form'),
            searchInput: this.querySelector('input[type="text"].search-input'),
            resultsContainer: this.querySelector('ul.program-search-form__results'),
            noResultsContainer: this.querySelector('div.program-search-form__noresults'),
            noResultsSearchTerm: this.querySelector('div.program-search-form__noresults span.search-term'),
            searchIcon: this.querySelector('.search-icons svg'),
            spinnerIcon: this.querySelector('.search-icons i'),
            resetBtn: this.querySelector('div.program-search-form__noresults button.search-reset')
        };

        this.settings = {
            useInternationalRoot: this.dataset.useInternationalRoot !== undefined
        };

        this.state = {
            previousTerm: null,
            previousTermResultCount: 0
        };

        this.search = debounce(this.search.bind(this), 300);

        this.elements.searchInput.addEventListener("keydown", (e) => {
            switch (e.key) {
                case 'Enter':
                    this.handleEnter(e);
                    break;
                case 'Tab':
                    if (this.state.showingNoResults)
                        return;

                    this.close();
                    break;
                case 'Up':
                case 'ArrowUp':
                    this.handleUp(e);
                    break;
                case 'Down':
                case 'ArrowDown':
                    this.handleDown(e);
                    break;
            };
        });

        this.elements.searchInput.addEventListener('input', e => {
            this.search()
        });

        this.elements.wrapper.addEventListener('focusin', e => {
            e.preventDefault();

            if (document.activeElement === this.elements.resetBtn)
                return;

            this.abortController = new AbortController();

            if (this.state.previousTerm !== this.elements.searchInput.value) {
                this.search();
                return;
            }

            if (this.state.previousTermResultCount > 0)
                this.openResults()
            else
                this.openNoResults();
        });

        this.elements.wrapper.addEventListener('focusout', e => {
            if (this.elements.wrapper.contains(e.relatedTarget)) {
                return;
            }

            this.close();
        });

        this.elements.resetBtn.addEventListener('click', e => {
            e.preventDefault();

            this.abort();
            this.elements.searchInput.value = '';
            this.elements.searchInput.focus();
            this.search()
        });
    }

    search() {
        const params = new URLSearchParams({
            term: this.elements.searchInput.value,
            useInternationalRoot: this.settings.useInternationalRoot
        });

        this.elements.searchIcon.style.display = 'none';
        this.elements.spinnerIcon.style.display = '';

        fetch('/api/programs/search?' + params, { signal: this.abortController.signal })
            .then(response => console.log(response.status) || response)
            .then(response => response.json())
            .then(items => {
                const listItems = items.map((item, index) => this.mapResultItem(item, index));
                this.elements.resultsContainer.replaceChildren(...listItems);
                this.state.previousTerm = this.elements.searchInput.value;
                this.state.previousTermResultCount = items.length;

                if (items.length == 0) {
                    this.openNoResults();
                } else {
                    this.openResults();
                }
            }).catch(err => {
                if (err.name === "AbortError") {
                    return;
                }

                console.error(err);
            }).finally(() => {
                this.elements.searchIcon.style.display = '';
                this.elements.spinnerIcon.style.display = 'none';
            });
    }

    abort() {
        this.abortController.abort();
        this.abortController = new AbortController();
    }

    handleEnter(e) {
        e.preventDefault();

        const selectedItem = this.getSelectedItem();

        if (!selectedItem)
            return;

        this.handleItemSelected(selectedItem.firstElementChild);
    }

    handleUp(e) {
        e.preventDefault();

        const selectedItem = this.getSelectedItem();

        //has previous
        if (selectedItem && selectedItem.previousElementSibling) {
            this.setActiveItem(selectedItem.previousElementSibling, selectedItem);
            return;
        }

        //doesn't have previous
        if (selectedItem && !selectedItem.previousElementSibling) {
            this.setActiveItem(this.elements.resultsContainer.lastElementChild, selectedItem);
            return;
        }

        //has nothing selected
        if (!selectedItem && this.elements.resultsContainer.lastElementChild) {
            this.setActiveItem(this.elements.resultsContainer.lastElementChild);
            return;
        }
    }

    handleDown(e) {
        e.preventDefault();

        const selectedItem = this.getSelectedItem();

        //has next
        if (selectedItem && selectedItem.nextElementSibling) {
            this.setActiveItem(selectedItem.nextElementSibling, selectedItem);
            return;
        }

        //doesn't have next
        if (selectedItem && !selectedItem.nextElementSibling) {
            this.setActiveItem(this.elements.resultsContainer.firstElementChild, selectedItem);
            return;
        }

        //has nothing selected
        if (!selectedItem && this.elements.resultsContainer.firstElementChild) {
            this.setActiveItem(this.elements.resultsContainer.firstElementChild);
            return;
        }
    }

    handleItemSelected(item) {
        this.elements.searchInput.value = item.textContent;
        window.location.href = item.href;
        this.close();
    }

    setActiveItem(newlySelectedItem, previouslySelectedItem) {
        if (previouslySelectedItem)
            previouslySelectedItem.removeAttribute('aria-selected');

        newlySelectedItem.setAttribute('aria-selected', 'true');
        this.setActiveDescendant(newlySelectedItem.id);
        this.elements.searchInput.value = newlySelectedItem.firstElementChild.textContent;

        newlySelectedItem.scrollIntoView({
            behavior: 'smooth',
            block:'nearest'
        });
    }

    setActiveDescendant(option) {
        if (option) {
            this.elements.searchInput.setAttribute('aria-activedescendant', option.id);
            return;
        }

        this.elements.searchInput.setAttribute('aria-activedescendant', '');
    }

    getSelectedItem() {
        return this.elements.resultsContainer.querySelector('li[aria-selected="true"]');
    }

    mapResultItem(item, index) {
        const innerMarkup = this.domParser.parseFromString(item.name, 'text/html');

        const anchor = document.createElement('a');
        anchor.href = item.url;
        anchor.replaceChildren(...innerMarkup.body.childNodes);

        anchor.addEventListener('click', e => {
            e.preventDefault();
            this.handleItemSelected(anchor);
        });

        const li = document.createElement('li');
        li.id = `${this.elements.resultsContainer.id}-opt-${index + 1}`;
        li.role = 'option';
        li.appendChild(anchor);

        return li;
    }

    clearActiveItems() {
        this.setActiveDescendant(false);

        for (const child of this.elements.resultsContainer.children) {
            child.removeAttribute('aria-selected');
        }
    }

    openNoResults() {
        this.showingNoResults = true;
        this.closeResults();
        this.elements.wrapper.classList.add('is-open');
        this.elements.noResultsSearchTerm.textContent = this.elements.searchInput.value;
        this.elements.noResultsContainer.style.display = 'block';
        this.elements.searchInput.ariaExpanded = 'true';
    }

    openResults() {
        this.showingNoResults = false;
        this.closeNoResults();
        this.elements.wrapper.classList.add('is-open');
        this.elements.resultsContainer.style.display = 'block';
        this.elements.searchInput.ariaExpanded = 'true';
    }

    closeResults() {
        this.clearActiveItems();
        this.elements.resultsContainer.style.display = 'none';
    }

    closeNoResults() {
        this.clearActiveItems();
        this.elements.noResultsContainer.style.display = 'none';
        this.elements.noResultsSearchTerm.textContent = '';
    }

    close() {
        this.abortController.abort();
        this.clearActiveItems();
        this.elements.searchInput.ariaExpanded = 'false';
        this.elements.wrapper.classList.remove('is-open');
        this.elements.resultsContainer.style.display = 'none';
        this.elements.noResultsContainer.style.display = 'none';
    }
}

export default AdProgramSearch;