import MicroModal from "micromodal";

class AdPopup extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.settings = {
            blockId: this.id,
            automaticOpenDelay: parseInt(this.dataset.openDelay)
        }

        setTimeout(() => this.openPopup(), this.settings.automaticOpenDelay * 1000);
    }

    openPopup() {
        MicroModal.show(`popup-${this.settings.blockId}`, {
            disableScroll: true,
            disableFocus: true
        });
    }
}

export default AdPopup;